import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons"

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="カテゴリーリスト"
      pagedesc="カテゴリーのリストです。"
      pagepath={location.pathname} />
    <section className="content categorylist">
      <div className="container">
        <h2 className="pagetitle">カテゴリー</h2>
          {data.allMicrocmsCategory.edges.map(({ node }) => (
              <div className="category" key={node.id}>
                <Link to={`/cat/${node.categorySlug}/`}>
                  <h3>
                    <FontAwesomeIcon icon={faFolderOpen} />
                    {node.category}
                  </h3>
                </Link>
              </div>
          ))}
      </div>
    </section>
    
  </Layout>
)

export const query = graphql`
  query {
    allMicrocmsCategory {
      edges {
        node {
          id
          category
          categorySlug
        }
      }
    }
  }
`
